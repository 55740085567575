"use client"

import { useEffect, useState } from "react"
import Image from "next/image"
import Slider from "react-slick"
import { motion } from "framer-motion"
import link from "next/link"
import { usePathname } from "next/navigation"
import styles from '@/components/CommonComponent/employees/EmpSlider.module.css';
import Button from '@/components/CommonComponent/button'
import { useRouter } from "next/navigation"
interface Employee {
    id: number
    name: string
    designation: string
    image: string
    image_alt: string
    is_featured: boolean,
    is_publish: boolean
    icon: string
}

interface EmpSliderProps {
    emp_data: Employee[]
}

const EmpSlider = ({ emp_data }: EmpSliderProps) => {
    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(() => {
        setWindowWidth(window.innerWidth)
        const handleResize = () => {
            setWindowWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

const Router =useRouter()
    const itemsPerRow = windowWidth < 640 ? 2 : windowWidth < 1024 ? 3 : windowWidth < 1500 ? 4 : 5
    const rowsPerSlide = windowWidth < 640 ? 1 : 2
    const itemsPerSlide = itemsPerRow * rowsPerSlide
    const pathname = usePathname();
    const featuredData = pathname === "/" ? emp_data?.filter(emp => emp.is_featured) : emp_data;
    const slides = [];
    for (let i = 0; i < featuredData.length; i += itemsPerSlide) {
        slides.push(featuredData.slice(i, i + itemsPerSlide));
    }


    const settings = {
        // dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                },
            },
        ],
    }

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            },
        },
    }
    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
            },
        },
    }

    return (
        <div className="w-full overflow-hidden px-4 sm:px-6">
            <Slider {...settings}>
                {slides.map((slide, slideIndex) => (
                    <div key={slideIndex}>
                        <motion.div
                            className={`grid gap-4 sm:gap-6 lg:gap-8 xl:gap-10 mx-auto w-full 
                                ${windowWidth < 640 ? "grid-cols-2 grid-rows-1" :
                                    windowWidth < 1024 ? "grid-cols-3 grid-rows-2" :
                                        windowWidth < 1500 ? "grid-cols-4 grid-rows-2" :
                                            "grid-cols-5 grid-rows-2"}
                            `}
                            variants={containerVariants}
                            initial="hidden"
                            animate="visible"
                        >
                            {slide.map((employee, index) => (
                                <motion.div
                                    key={`${slideIndex}-${index}`}
                                    className="flex flex-col  outer-container items-center justify-center mx-2 text-center"
                                    variants={itemVariants}
                                >
                                    <div
                                        className="relative z-20 flex items-end justify-center rounded-full w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 xl:w-52 xl:h-52 
                                        bg-black/20 hover:bg-[#D4ECF7]
    group transition-all duration-300 ease-in-out overflow-visible mb-4"
                                    >
                                        <div className="absolute grayscale hover:grayscale-0 inset-0 rounded-full overflow-hidden top-2 group-hover:top-0 transition-all duration-200 ease-in-out">
                                            <div className="w-full h-full">
                                                <Image
                                                    src={employee.image || "/placeholder.svg"}
                                                    fill
                                                    sizes="(max-width: 640px) 8rem, (max-width: 768px) 10rem, 12rem"
                                                    alt={`Profile image of ${employee.image_alt}`}
                                                    className="object-contain group-hover:scale-105 transition-all duration-200 ease-in-out"
                                                    loading="lazy"
                                                />
                                            </div>
                                        </div>
                                        {/* Fixed Z-Index & Top Positioning */}
                                        <div
                                            className="absolute border-white border-4 z-[9999] fade-in w-[50px] h-[50px] sm:w-[55px] sm:h-[55px]  right-5 flex items-center justify-center rounded-full bg-blue"
                                        >
                                            <span className="text-white text-xl font-semibold flex items-center justify-center">
                                                <Image src={employee?.icon || "/images/Vector (1).png"} alt="chevron icon" width={25} height={25} />
                                            </span>
                                        </div>
                                    </div>
                                    <p className="text-sm sm:text-base font-bold text-center">{employee.name}</p>
                                    <p className="text-xs sm:text-sm text-homegrey text-center">{employee.designation}</p>
                                </motion.div>
                            ))}
                        </motion.div>
                    </div>
                ))}
            </Slider>
{/* <link href="/meet-our-team"> */}
            {/* <div className="w-full  mt-12 flex justify-center items-center" onClick={()=>{Router.push('/meet-our-team')}}>
            <Button content="Meet Our Team " />
            </div> */}
{/* </link> */}
        </div>
    )
}
export default EmpSlider

