'use client'
import styled from 'styled-components'
 export const StyledWrapper = styled.div`
  ul {
    list-style: disc;
  }
 
  h1,h2,h6,h3,h4,h5,h6 {
      color: #333333;
  }
      strong{
      color: #333333;
      }
 p ,li ,td {
  color: #535353; /* Replace with your color */
}
  ol, ul {
    padding-left: 1.5rem;
    list-style-type:disc;
    color: #535353;
    font-size:20px!important;
  }
    table{
    border:1px solid black;
    margin-top:26px ;
    margin-bottom:26px
    color: #535353;
    width:100%!important ;
    min-width:200px;
    overflow-x:auto !important
    }
    td{
    padding:15px;
    color: #535353;
    border:1px solid black;
    font-size:20px !important;
    }
     .table-container {
      width: 100%;
      overflow-x: auto;
    }
a {
   /* Default text color */
   /* Default font weight */
  text-decoration: underline; /* Underline the link */
  text-decoration-color: #1090d0; /* Set default underline color to blue */
  text-decoration-thickness: 2px; /* Set the thickness of the underline to 2px */
  text-underline-offset: 3px;
  text-underline-offset: 5px; /* Optional: adjust the distance of the underline from the text */
}
a:hover {
  text-decoration-color: #EF1649; /* Change underline color to pink on hover */
}
`
