import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/CommonComponent/blogs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/CommonComponent/clientSays/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/CommonComponent/DownNavbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/CommonComponent/employees/page.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/CommonComponent/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/CommonComponent/navbar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/Banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/Certificate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/Choose.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledWrapper"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/contentSect/styles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledWrapper"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/contentSect2/styles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StyledWrapper"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/contentSect3/styles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/faqComp/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/Form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/marketingGrowth/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/ProvenResult.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Home/ValuedClients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Marketing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/OtherComponent/websiteReport/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Pages/FooterPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/serviceComponent/Technique2.tsx");
