"use client";
import Link from 'next/link';
import { Useapi } from '@/helpers/apiContext';
import { MdWifiCalling3 } from "react-icons/md";
import { FiTool } from 'react-icons/fi';
const Header = () => {
  const { basic_details } = Useapi();
  return (
    <>
      <div className='bg-purple flex justify-between px-6 py-3  sm:hidden text-white'>
      <div
                className="relative   sm:hidden"
              >
                <button
                  className="relative  bg-pinkdarker flex items-center justify-center  px-4 md:px-6 text-white rounded-lg py-2 group bg-pink-500 transition duration-300 overflow-hidden"
                >
                  <Link href={'https://app.w3era.com/'} target="_blank"
                    aria-label="Explore Seo tools" title="Explore Seo tools"
                  >
                    <span className="redirect-to-app_w3era-laptop">
                      Explore New SEO Tools
                    </span>
                  </Link>
                  <Link href={'https://app.w3era.com/'} target="_blank"
                    aria-label="Explore Seo tools" title="Explore Seo tools"
                  >
                    <span className="redirect-to-app_w3era-mobile">
                      New SEO Tools
                    </ span>
                  </Link>
                  <FiTool className="text-[20px] hidden sm:block opacity-0 group-hover:opacity-100 transition duration-300 group-hover:translate-x-2" />
                </button>
              </div>
                <span
                  className="font-semibold  sm:hidden flex items-center justify-center px-2 w-fit py-[8px] 2xl:py-3
               text-white bg-pink hover:bg-pink-700 rounded-full transition duration-300 text-[24px]"
                  style={{
                    animation: "shake 0.5s ease-in-out infinite",
                    display: "inline-block",
                  }}
                >{
                 ( basic_details?.basic_details[0]?.us_number     ||       basic_details?.basic_details[0]?.phonenumber )   &&   <Link
                  href={`tel:${basic_details?.basic_details[0]?.country === 'US'
                    ? (basic_details?.basic_details[0]?.us_number||'')
                    : (basic_details?.basic_details[0]?.phonenumber||'')
                    }`}
                    >
                    <MdWifiCalling3  />
                  </Link>
                  }
                </span>
          
          </div>
        
    </>
  );
};

export default Header;
