"use client";
import { StyledWrapper } from '@/components/Home/contentSect/styles';

const Growth = ({ Data }: any) => {
    // const [Data, setData] = useState<any>(); // Initial state should be null
    // const { apidata } = Useapi(); // Destructure apidata from the context
    return (
        <div className="w-full">
            <div className="flex flex-wrap w-full">
                {Data.map((elem: any, index: number) => (
                    <div
                        key={index}
                        className={`w-full ${index % 2 == 0 ? 'sm:border-r-2 ' : 'border-r-0'} ${index < Data.length - 2 ? 'border-b-2 ' : ' border-b-2 sm:border-b-0'} md:w-1/2 px-4 border-blue flex`}>
                        <div className="w-full flex flex-col justify-between py-3 sm:py-6  rounded-md ">
                            <StyledWrapper>
                                <div
                                    className="text-homegrey text-center lg:text-left"
                                    dangerouslySetInnerHTML={{ __html: elem?.description }}
                                ></div>
                            </StyledWrapper>
                        </div>
                    </div>
                ))}
            </div>
        </div>);
};
export default Growth;