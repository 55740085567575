'use client'
import styled from 'styled-components'
 export const StyledWrapper = styled.div`

// a {
//    /* Default text color */
//    /* Default font weight */
//   text-decoration: underline; /* Underline the link */
//   text-decoration-color: #1090d0; /* Set default underline color to blue */
//   text-decoration-thickness: 2px; /* Set the thickness of the underline to 2px */
//   text-underline-offset: 3px;
//   text-underline-offset: 5px; /* Optional: adjust the distance of the underline from the text */
// }
// a:hover {
//   text-decoration-color: #EF1649; /* Change underline color to pink on hover */
// }
a strong{
  color:
  }
 ol, ul {
    padding-left: 1.5rem;
    list-style-type:disc;
    color: #535353;
    font-size:20px!important;
  }
`
