"use client"
import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import dynamic from "next/dynamic";
import { GoArrowUpRight } from "react-icons/go";
import { StyledWrapper } from '@/components/Home/contentSect/styles';

// import Button from '@/components/button';
import Link from 'next/link';
const Button = dynamic(() => import('@/components/CommonComponent/button'), { ssr: false });
const Marketing = ({props}:any) => {
  const [marketingData, setmarketingData] = useState<any>(); // Initial state should be null
  // const { apidata } = Useapi(); // Destructure apidata from the context
  const [data, setdata] = useState({first:'',second:''})

  function splitStringByLastWords(text:any, numOfWords:number) {
    const words = text.split(' '); // Split the string by spaces to get individual words
    
    if (numOfWords >= words.length) {
        return { first: '', second: text };
    }
    const splitIndex = words.length - numOfWords;
    const firstPart = words.slice(0, splitIndex).join(' ');
    const secondPart = words.slice(splitIndex).join(' ');
    return { first: firstPart, second: secondPart };
}
  useEffect(() => {
    const result = splitStringByLastWords(marketingData?.title||'', 3);
    setdata(result);
}, [marketingData]);
  useEffect(() => {
    if (props ) {
      setmarketingData(props);
    }
  }, [props]);  
  return (
      <div  className="flex justify-between pb-12 flex-col md:flex-row items-center  px-4 w-full xl:w-[95%]  2xl:w-[80%] mx-auto">
        <div className={`${marketingData?.image ? "lg:w-1/2" : "w-full"} text-center flex flex-col gap-3 md:text-left`}>
          <p className='text-homeblack text-[18px] lg:text-[20px] font-semibold  uppercase'>{marketingData?.subtitle}</p>
          <h2 className='  font-bold  text-homeblack  lg:leading-[58px]'>{data.first} <span className='text-pink'>{data.second}</span></h2>
          <StyledWrapper>
          <div className='text-homegrey text-[16px] lg:text-[18px]' dangerouslySetInnerHTML={{__html: marketingData?.summary}}></div>
          </StyledWrapper>
          <div className='flex gap-4 mt-5 flex-wrap  md:flex-nowrap lg:flex-wrap 2xl:flex-nowrap justify-between'>
            {/* <div className="bg-lightblue text-homeblack text-[16px] lg:text-[18px] p-5 rounded-lg items-start flex gap-4 group hover:bg-white duration-300 hover:shadow-lg transition-all">
              {marketingData?.box1}
              
              <div className="rounded-full bg-white flex justify-center items-center min-w-[45px] h-[45px] group-hover:bg-lightpink transition-colors duration-300">
                <GoArrowUpRight className="transform transition-transform duration-300 -rotate-30 text-[28px] text-pink group-hover:rotate-45" />
              </div>
            </div> */}
            {/* <div className="bg-lightblue p-5 rounded-lg text-homeblack text-[16px] lg:text-[18px] items-start flex gap-4 group hover:bg-white duration-300 hover:shadow-lg transition-all">
              {marketingData?.box2}
          
              <div className="rounded-full bg-white flex justify-center items-center min-w-[45px] h-[45px] group-hover:bg-lightpink transition-colors duration-300">
                <GoArrowUpRight className="transform transition-transform duration-300 -rotate-30 text-[28px] text-pink group-hover:rotate-45" />
              </div>
            </div> */}
          </div>
          <div className='w-full flex justify-center sm:w-fit mt-6'>
            <Link href={'/contact-us'} aria-label="contact us" >
          <Button content={'Get a Free Digital Marketing Consultation'}/>
            </Link>
          </div>
        </div>

        {marketingData?.image &&
        <div className="mt-7 md:hidden lg:block md:mt-0">
          <Image
            src={marketingData?.image || ""}
            alt={marketingData?.image_alt || ""}
            height={580}
            width={588}
          />
        </div>}
      </div>
  )
}

export default Marketing
