"use client";

import { useRef } from "react";
import { Form, Input, Button, message } from "antd";
import { useRouter } from "next/navigation";

export default function ContactForm() {
    const formRef = useRef<any>(null);
    const router = useRouter();

    const handleSubmit = async (values: any) => {
        if (!window.grecaptcha) {
            message.error("reCAPTCHA failed to load. Please refresh the page.");
            return;
        }
        try {
            // Request reCAPTCHA token
            const token = await window.grecaptcha.execute(
                "6Ld9Q7kqAAAAACUZQAsyTE39c4sKSsf66u5um8MX",
                { action: "submit" }
            );

            const fullPhoneNumber = `${values.phoneCode}${values.phoneNumber}`;

            if (values.phoneNumber.length !== 10) {
                message.error("Phone number must be exactly 10 digits!");
                return;
            }

            const response = await fetch(
                "https://api.hsforms.com/submissions/v3/integration/submit/20095080/eba66f02-7161-451a-95bd-d4d8a30f0a13",
                {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        fields: [
                            { name: "firstname", value: values.name },
                            { name: "email", value: values.email },
                            { name: "phone", value: fullPhoneNumber },
                        ],
                    }),
                }
            );

            if (response.ok) {
                message.success("Form submitted successfully!");
                formRef.current.resetFields();
                router.push("/thank-you");
            } else {
                message.error("Failed to submit form. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            message.error("An unexpected error occurred. Please try again.");
        }
    };

    return (
        <div className="bg-[url('/blog/form-bg.png')] rounded-xl w-full p-5 2xl:p-10 py-14 bg-center bg-no-repeat bg-cover">
            <p className="text-white text-[24px] text-center 2xl:text-[28px] pb-5 font-semibold">Not sure which strategy is best for your business?</p>
            <span className="text-white text-[16px]">Let’s build a custom plan for you!</span>
            <Form ref={formRef} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                    // label="Full Name"
                    name="name"
                    rules={[{ required: true, message: "Please enter your name" }, { pattern: /^[A-Za-z\s]+$/, message: "Only alphabets are allowed" },
                    ]}
                >
                    <Input placeholder="Enter your full name" />
                </Form.Item>
                <Form.Item
                    // label="Email"
                    name="email"
                    rules={[
                        { required: true, message: "Please enter your email" },
                        { type: "email", message: "Enter a valid email" },

                    ]}
                >
                    <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item
                    // label="Phone Number"
                    name="phoneNumber"
                    rules={[
                        { required: true, message: "Please enter your phone number" },
                        { len: 10, message: "Phone number must be 10 digits" },
                    ]}
                >
                    <Input type="number" placeholder="Enter your phone number" />
                </Form.Item>
                <Form.Item>
                    <button className="w-full bg-pink  rounded-full border-[1px] border-white text-white py-2 text-[16px] font-semibold">
                        Get A Quote
                    </button>
                </Form.Item>
            </Form>
            <div className="text-white mx-auto text-center text-[16px]">*We will get back to you within 3 days</div>
        </div>
    );
}
